<template>
  <v-container>
    <h1>Политика конфиденциальности</h1>
    <p>
      Юридический адрес 109147, Россия, г.Москва, ул.Воронцовская, д.35Б, корп.
      2, 4 этаж, II помещение, 7 комн.
    </p>
    <p>
      При обработке персональных данных мы стремимся соблюдать требования
      законодательства Российской Федерации, в частности Федеральный закон №
      152-ФЗ от 27 июля 2006 г. «О персональных данных», а также нормы и
      правила, установленные в ООО «БПО». Неотъемлемой частью настоящий Политики
      и их частью является ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ ООО «БПО»
    </p>
    <h2>Термины и определения</h2>
    <p>
      Персональные данные - любая информация, относящаяся прямо или косвенно к
      определенному или определяемому физическому лицу (гражданину). Т.е. к
      такой информации, в частности, можно отнести: фамилию, имя, отчество,
      адрес, номер основного документа, удостоверяющего его личность, сведения о
      дате выдачи указанного документа и выдавшем его органе , год, месяц, дата
      и место рождения, адрес, контактный телефон, электронный адрес, сведения о
      семейном, социальном, имущественном положении, сведения об образовании,
      профессии, доходах, сведения о состоянии здоровья, а также другую
      информацию, включая ряд технических данных: IP адрес, cookie, историю
      посещений браузера и другие. Если Пользователь является представителем
      юридического лица, он согласен предоставить информацию, относящуюся к
      реквизитам юридического лица: наименование, ИНН, юридический адрес, виды
      деятельности, наименование и ФИО исполнительного органа.
    </p>
    <p>
      Обработка персональных данных - любое действие (операция) или совокупность
      действий (операций) с персональными данным, совершаемых с использованием
      средств автоматизации или без использования таких средств. К таким
      действиям (операциям) можно отнести: сбор, получение, запись,
      систематизацию, накопление, хранение, уточнение (обновление, изменение),
      извлечение, использование, передачу (распространение, предоставление,
      доступ), обезличивание, блокирование, удаление, уничтожение персональных
      данных.
    </p>
    <p>
      Конфиденциальность персональных данных – обязательное для соблюдения
      Обществом или иными получившими доступ к персональным данным лицом
      требование не допускать их распространения без согласия субъекта
      персональных данных или наличия иного законного основания.
    </p>
    <p>
      Пользователь – лицо, имеющее доступ к Сайту, посредством сети Интернет и
      использующее сайт <a :href="link">{{ link }}</a> для просмотра информации,
      бронирования и приобретения услуг.
    </p>
    <p>
      Политика – настоящая политика в отношении обработки персональных данных.
    </p>
    <p>
      Субъект персональных данных - физическое лицо, прямо или косвенно
      определенное или определяемое на основании относящихся к нему персональных
      данных.
    </p>
    <p>
      Уничтожение персональных данных - действия, в результате которых
      становится невозможным восстановить содержание Персональных данных в
      Информационной системе персональных данных и (или) в результате которых
      уничтожаются материальные носители персональных данных.
    </p>
    <p>
      Использование Пользователем сайта
      <a href="link">{{ link }}</a> приобретение туристского продукта или
      получение консультации , обозначает безоговорочное и полное согласие с
      настоящей Политикой в отношении обработки и защиты персональных данных
      Пользователя, в порядке ст. 9 Федерального закона № 152-ФЗ от 27 июля 2006
      г. «О персональных данных»
    </p>
    <p>
      Политика ООО "БПО" (далее по тексту также - Общество) в отношении
      обработки персональных данных» (далее - Политика) определяет позицию и
      намерения Общества в области обработки и защиты персональных данных, с
      целью соблюдения и защиты прав и свобод каждого человека и, в особенности,
      права на неприкосновенность частной жизни, личную и семейную тайну, защиту
      своей чести и доброго имени.
    </p>
    <p>
      Политика неукоснительно соблюдается руководителями и работниками всех
      структурных подразделений и филиалов ООО «БПО».
    </p>
    <p>
      Действие настоящей Политики распространяется на все персональные данные
      субъектов, обрабатываемые в Обществе с применением средств автоматизации и
      без применения таких средств.
    </p>
    <p>
      К настоящей Политике имеет доступ любой субъп любой субъект персональных
      данных.
    </p>
    <p>
      Политика разработана с учетом требований Конституции Российской Федерации,
      законодательных и иных нормативных правовых актов Российской Федерации в
      области персональных данных.
    </p>
    <p>
      Настоящая политика в отношении обработки и защиты персональных данных
      применяется только к сайту
      <a href="link">{{ link }}</a> Общество не контролирует и не несет
      ответственность за сайты третьих лиц, на которые Пользователь может
      перейти по ссылкам, доступным на сайте
      <a href="link">{{ link }}</a>
    </p>
    <h2>Принципы и условия обработки персональных данных</h2>
    <p>
      Под безопасностью персональных данных Общество понимает защищенность
      персональных данных от неправомерного или случайного доступа к ним,
      уничтожения, изменения, блокирования, копирования, предоставления,
      распространения персональных данных, а также от иных неправомерных
      действий в отношении персональных данных и принимает необходимые правовые,
      организационные и технические меры для защиты персональных данных.
    </p>
    <p>
      Обработка и обеспечение безопасности персональных данных в Обществе
      осуществляется в соответствии с требованиями Конституции Российской
      Федерации, Федерального закона № 152-ФЗ от 27 июля 2006 г. «О персональных
      данных», подзаконных актов, других определяющих случаи и особенности
      обработки персональных данных федеральных законов Российской Федерации,
      руководящих и методических документов ФСТЭК России и ФСБ России.
    </p>
    <p>
      При обработке персональных данных Общество придерживается следующих
      принципов:
    </p>
    <ul>
      <li>законности и справедливой основы;</li>
      <li>
        ограничения обработки персональных данных достижением конкретных,
        заранее определенных и законных целей;
      </li>
      <li>
        недопущения обработки персональных данных, несовместимой с целями сбора
        персональных данных;
      </li>
      <li>
        недопущения объединения баз данных, содержащих персональные данные,
        обработка которых осуществляется в целях, несовместимых между собой;
      </li>
      <li>
        обработки персональных данных, которые отвечают целям их обработки;
      </li>
      <li>соответствия содержания.</li>
    </ul>
    <p>
      Общество обрабатывает персональные данные только при наличии хотя бы
      одного из следующих условий:
    </p>
    <ul>
      <li>
        обработка персональных данных осуществляется с согласия субъекта
        персональных данных на обработку его персональных данных;
      </li>
      <li>
        обработка персональных данных необходима для достижения целей,
        предусмотренных законом, для осуществления и выполнения возложенных
        законодательством Российской Федерации на оператора функций, полномочий
        и обязанностей;
      </li>
      <li>
        обработка персональных данных необходима для исполнения договора,
        стороной которого либо выгодоприобретателем или поручителем по которому
        является субъект персональных данных, а также для заключения договора по
        инициативе субъекта персональных данных или договора, по которому
        субъект персональных данных будет являться выгодоприобретателем или
        поручителем;
      </li>
      <li>
        обработка персональных данных необходима для осуществления прав и
        законных интересов Общества или третьих лиц либо для достижения
        общественно значимых целей при условии, что при этом не нарушаются права
        и свободы субъекта персональных данных;
      </li>
      <li>
        осуществляется обработка персональных данных, доступ неограниченного
        круга лиц к которым предоставлен субъектом персональных данных либо по
        его просьбе;
      </li>
      <li>
        осуществляется обработка персональных данных, подлежащих опубликованию
        или обязательному раскрытию в соответствии с федеральным законом.
      </li>
    </ul>
    <p>
      Общество вправе поручить обработку персональных данных граждан третьим
      лицам на основании заключаемого с этими лицами договора, в случаях, когда
      это необходимо для оказания услуг по бронированию туристического продукта
      и выполнения заказа Пользователя – авиакомпаниям; туристическим
      операторам, отелям; визовым центрам; консульствам; курьерским службам;
      организациям почтовой связи; провайдерам Интернет услуг; организациям,
      предоставляющим отправку электронных писем. Лица, осуществляющие обработку
      персональных данных по поручению Общества, обязуются соблюдать принципы и
      правила обработки и защиты персональных данных, предусмотренные
      Федеральным законом № 152-ФЗ от 27 июля 2006 г. «О персональных данных».
      Для каждого лица определены перечень действий (операций) с персональными
      данными, которые будут совершаться юридическим лицом, осуществляющим
      обработку персональных данных, цели обработки, установлена обязанность
      такого лица соблюдать конфиденциальность и обеспечивать безопасность
      персональных данных при их обработке, а также указаны требования к защите
      обрабатываемых персональных данных.
    </p>
    <p>
      В случаях, установленных законодательством Российской Федерации, Общество
      вправе осуществлять передачу персональных данных граждан.
    </p>
    <p>
      Общество уничтожает либо обезличивает персональные данные по достижении
      целей обработки или в случае утраты необходимости достижения цели
      обработки, а также в случае отзыва субъектом согласие на обработку
      персональных данных.
    </p>
    <p>
      Обработка персональных данных в Обществе осуществляется с согласия
      субъекта персональных данных на обработку его персональных данных, если
      иное не предусмотрено законодательством Российской Федерации в области
      персональных данных.
    </p>
    <p>
      Адреса электронной почты, номера телефонов, полученные Обществом, могут
      использоваться Обществом для отправки пользователям сообщений, в т.ч. и
      рассылок с правом сообщать Субъекту персональных данных любую информацию
      коммерческого и (или) некоммерческого, информационного характера об
      оказании туристских услуг и (или) продвижения туристического продукта и
      (или) отправлять рекламно-информационные материалы о третьих лицах, в т.ч.
      с условиями оказания туристских услуг и (или) продвижения туристического
      продукта посредством отправления рассылок по электронной почте, в
      SMS-сообщениях через средства связи, с сообщениях через месседжеры Viber,
      WhatsApp, Telegram, Субъекта персональных данных . Пользователи Общества
      всегда могут отписаться от получения таких рассылок в будущем, через
      форму, указанную в отправленной рассылке, и (или) сообщив на адрес
      info@bpo.travel
    </p>
    <p>
      Общество не обязано проверять достоверность предоставленной Пользователем
      информации, и не осуществляет контроль дееспособности Пользователя. При
      этом Общество исходит из предоставления Пользователем достоверной и
      достаточной персональной информации в соответствующих графах, обозначенных
      в регистрационной форме, и поддержания Пользователем указанной информацию
      в актуальном состоянии. Риск предоставления недостоверной информации несет
      предоставивший ее Пользователь.
    </p>
    <h2>Права субъекта персональных данных</h2>
    <p>
      Гражданин, персональные данные которого обрабатываются Обществом, имеет
      право получать от Общества:
    </p>
    <ul>
      <li>подтверждение факта обработки персональных данных Обществом;</li>
      <li>правовые основания и цели обработки персональных данных;</li>
      <li>
        сведения о применяемых Обществом способах обработки персональных данных;
      </li>
      <li>наименование и местонахождения Общества;</li>
      <li>
        сведения о лицах, которые имеют доступ к персональным данным или которым
        могут быть раскрыты персональные данные на основании договора с
        Обществом или на основании федерального закона;
      </li>
      <li>
        перечень обрабатываемых персональных данных, относящихся к гражданину,
        от которого поступил запрос и источник их получения, если иной порядок
        предоставления таких данных не предусмотрен федеральным законом;
      </li>
      <li>
        сведения о сроках обработки персональных данных, в том числе о сроках их
        хранения;
      </li>
      <li>
        сведения о порядке осуществления гражданином прав, предусмотренных
        Федеральным законом «О персональных данных» № 152-ФЗ от 27 июля 2006 г.;
      </li>
      <li>
        наименование и адрес лица, осуществляющего обработку персональных данных
        по поручению ООО «БПО»;
      </li>
      <li>
        иные сведения, предусмотренные Федеральным законом «О персональных
        данных» № 152-ФЗ от 27 июля 2006 г. или другими федеральными законами.
      </li>
    </ul>
    <p>
      Гражданин, персональные данные которого обрабатываются Обществом, имеет
      право:
    </p>
    <ul>
      <li>
        требовать уточнения своих персональных данных, их блокирования или
        уничтожения в случае, если случае, если персональные данные являются
        неполными, устаревшими, неточными, незаконно полученными или не являются
        необходимыми для заявленной цели обработки;
      </li>
      <li>отозвать свое согласие на обработку персональных данных;</li>
      <li>
        требовать устранения неправомерных действий Общества в отношении его
        персональных данных;
      </li>
      <li>
        на защиту своих прав и законных интересов, в том числе на возмещение
        убытков и/или компенсацию морального вреда в судебном порядке.
      </li>
    </ul>
    <h2>Ответственность</h2>
    <p>
      В случае неисполнения положений настоящей Политики Общество несет
      ответственность в соответствии действующим законодательством Российской
      Федерации.
    </p>
    <p>Меры по обеспечению безопасности персональных данных при их обработке</p>
    <p>
      Общество при обработке персональных данных принимает необходимые правовые,
      организационные и технические меры для защиты персональных данных от
      неправомерного или случайного доступа к ним, уничтожения, изменения,
      блокирования, копирования, предоставления, распространения персональных
      данных, а также от иных неправомерных действий в отношении персональных
      данных.
    </p>
    <p>
      К таким мерам в соответствии с Федеральным законом № 152-ФЗ от 27 июля
      2006 г. «О персональных данных» относятся:
    </p>
    <ul>
      <li>
        определение угроз безопасности персональных данных при их обработке в
        информационных системах персональных данных;
      </li>
      <li>
        определение угроз безопасности персональных данных при их обработке в
        информационных системах персональных данных;
      </li>
      <li>
        применение прошедших в установленном порядке процедуру оценки
        соответствия средств защиты информации;
      </li>
      <li>
        обнаружение фактов несанкционированного доступа к персональным данным и
        принятием мер;
      </li>
      <li>
        установление правил доступа к персональным данным, обрабатываемым в
        информационной системе персональных данных, а также обеспечением
        регистрации и учета всех действий, совершаемых с персональными данными в
        информационной системе персональных данных;
      </li>
      <li>
        ознакомление работников Общества, его филиалов и представительств,
        непосредственно осуществляющих обработку персональных данных, с
        положениями законодательства Российской Федерации и локальных
        нормативных актов Общества в области персональных данных, в том числе
        требованиями к защите персональных данных, и обучение указанных
        работников;
      </li>
      <li>
        контроль за принимаемыми мерами по обеспечению безопасности персональных
        данных и уровня защищенности информационных систем персональных данных.
      </li>
    </ul>
    <p>
      Изменения в Политике о конфиденциальности и защиты персональных данных
    </p>
    <p>
      Актуальная версия «Политики ООО «БПО» в отношении обработки персональных
      данных» публикуется в открытом доступе сети Интернет по адресу:
      <a href="link">{{ link }}</a> Общество может вносить изменения в “
      Политику о конфиденциальности и защиты персональных данных ” в
      одностороннем порядке, в т.ч. и без предварительного уведомления
      пользователей.
    </p>
    <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
    <p>
      Настоящее Соглашение определяет условия использования Пользователями
      материалов и сервисов сайта jets.bpo.travel (далее — «Сайт»).
    </p>
    <h2>1. ОБЩИЕ УСЛОВИЯ</h2>
    <p>
      1.1 Использование всех материалов и сервисов Сайта регулируется в
      соответствии с нормами действующего законодательства РФ.
    </p>
    <p>
      1.2 Настоящее Соглашение является публичной офертой. Получая доступ к
      материалам Сайта, Пользователь присоединяется к нему.
    </p>
    <p>
      1.3 Администрация Сайта вправе изменять условия Соглашения. Они вступают в
      силу по истечении трех дней. Пользователь, не согласный с изменениями,
      обязан прекратить использование материалов и сервисов Сайта.
    </p>
    <h2>2. ОБЯЗАТЕЛЬСТВА ПОЛЬЗОВАТЕЛЯ</h2>
    <p>
      2.1 Пользователь соглашается не нарушать своими действиями и комментариями
      на Сайте российские и международные нормы, в том числе в сфере
      интеллектуальной собственности, авторских и смежных правах, защиты чести и
      достоинства личности, а также вредить работе Сайта и сервисов.
    </p>
    <p>
      2.2 Согласно статье 1270 Гражданского кодекса, использовать материалы
      Сайта без согласия Правообладателей, зафиксированного в договоре, не до
      пускается.
    </p>
    <p>
      2.3 При цитировании материалов обязательна ссылка на Сайт (подпункт 1
      пункта 1 статьи 1274 Гражданского кодекса).
    </p>
    <p>
      2.4 Пользователь должен понимать, что все материалы и сервисы Сайта могут
      содержать рекламные материалы, ответственности за которые Администрация
      Сайта не несет.
    </p>
    <h2>3. ОБЯЗАТЕЛЬСТВА АДМИНИСТРАЦИИ</h2>
    <p>
      3.1 Администрация Сайта не несет ответственности за последствия, вызванные
      переходом по ссылкам на внешние ресурсы.
    </p>
    <p>
      3.2 Администрация не несет ответственности за потери и убытки
      Пользователя, вызванные регистрацией на Сайте, полученной информацией или
      услугами, доступными через переход по внешним ссылкам.
    </p>
    <p>
      3.3 Несвоевременная реакция Администрации Сайта на противоправные действия
      пользователя не исключает ее права на защиту интересов Правообладателей в
      любое другое время.
    </p>
    <h2>4. ПРОЧИЕ УСЛОВИЯ</h2>
    <p>
      4.1 Все споры, связанные с Соглашением, рассматриваются судебными органами
      в рамках действующего на территории РФ законодательства.
    </p>
    <p>
      4.2 Информация, представленная на Сайте, не может рассматриваться как
      партнерские отношения и носит исключительно ознакомительный характер.
    </p>
    <p>
      4.3 Решение суда, признающее недействительным какое-либо из положений
      настоящего Соглашения, не аннулирует его полностью.
    </p>
    <p>
      Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего
      Соглашения и принимает их.
    </p>
    <h1>СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
    <p>
      Я даю свое согласие ООО «БПО» (ИНН 7721252984, КПП 770901001, 2109147,
      Россия, г.Москва, ул.Воронцовская, д.35Б, корп. 2, 4 этаж, II помещение, 7
      комн., Телефон: 8 (495) 461 00 11) на обработку моих персональных данных
      (ФИО, серия и номер паспорта, контактный номер телефона, электронной
      адрес), с целью исполнения ООО «БПО» передо мной обязательств, прописанных
      в договоре о сотрудничестве, в том числе при продаже товаров и услуг.
    </p>
    <p>
      Мое согласие ООО «БПО» может использовать при проведении различного рода
      исследований, улучшении качества предоставляемых услуг, гарантируя при
      этом соблюдение законов, нормативных правовых актов Российской Федерации,
      а также внутреннего устава ООО «БПО».
    </p>
    <p>
      Я соглашаюсь на сбор, систематизацию, хранение и использование, блокировку
      и удаление моих персональных данных в случаях, предусмотренных действующим
      законодательством РФ, совершаемых с использованием специализированных
      средств.
    </p>
    <p>
      Я уведомлен, что вся персональная информация обо мне, как о клиенте, может
      быть передана третьим лицам (посредникам) для организации процесса оплаты
      используемых услуг или приобретаемых товаров.
    </p>
    <p>
      До моего сведения доведено, что ООО «БПО» гарантирует безопасность моих
      персональных данных. Срок действия данного согласия не ограничен. Согласие
      может быть аннулировано в любой момент по моему письменному заявлению в
      соответствии с требованиями законодательства Российской Федерации.
    </p>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  data() {
    return {
      link: "https://bpo.travel",
    };
  },
};
</script>

<style scoped></style>
